<template>
  <div>
    <form-render
        ref="formTypeUpload"
        :form.sync="formTypeUpload"
        :fields="fieldFormTypeUpload"
        :key="keyFormRenderTypeUpload"
        :disable-all="!isCountryAviable"
    />
    <p class="font-weight-bold mt-2" v-if="formTypeUpload.type.id !== 3">El archivo solo puede ser *.xls o *.xlsx, según el siguiente <a class="lnk lnk-primary" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">excel de ejemplo con formato válido para {{ selectedCountry && selectedCountry.name }}.</a></p>
    <div class="row" >
      <b-col md="6" class="mb-2 mt-2" v-if="formTypeUpload.type.id == 3">
        <p v-if="!currentCarrier">Seleccione un courier para importar/exportar la información.</p>
        <b-form-group
          label-for="carriersList"
          label="Courier"
        >
          <v-select
            id="carriersList"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="currentCarrier"
            :options="optionsCarriers"
            placeholder="Seleccione courier para cargar"
            label="text"
          />
        </b-form-group>
      </b-col>

      <div class="mt-0 pt-0 col-12 col-md-8" v-if="formTypeUpload.type.id !== 3 || (formTypeUpload.type.id === 3 && currentCarrier)">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileCost" v-cloak>
          <div class="w-100 p-2 file-drop-area">
            <b-input-group class="invoice-list-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="form.fileCost.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFileCost()" :disabled="loading.uploadCost">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileCost">
          <div class="file-drop-area" >
            <input type="file" name="fileCost" id="fileCost" class="file-input invisible" @change="changeFileCost" accept=".xls, .xlsx"/>
            <label for="fileCost" class="w-100 text-center cursor-pointer m-0">
              <feather-icon v-if="!loading.uploadCost" icon="UploadCloudIcon" size="30" class="mt-1" />
              <p v-if="!loading.uploadCost" class="mb-1">Subir Archivo</p>
              <p class="mt-1 p-2" v-if="loading.uploadCost"><b-spinner small  /> {{ $t('Enviando') }}</p>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-md-4" v-if="formTypeUpload.type.id !== 3 || (formTypeUpload.type.id === 3 && currentCarrier)">
        <b-button v-if="formTypeUpload.type.id == 3" @click="downloadTranslate()" block variant="outline-success">
          <!--<feather-icon icon="DownloadIcon" small/>-->
          <img src="/icon/export.svg" alt="exportar excel" class="icon-export">
          <b-spinner v-if="loading.downloadTranslate" small></b-spinner>
          <span v-else class="ml-1">Exportar excel</span>
        </b-button>
        <b-button block @click="uploadCostFile()" v-if="formTypeUpload.type.id !== 3" variant="success" :disabled="loading.uploadCost || !form.fileCost">
          <b-spinner v-if="loading.uploadCostTest" small></b-spinner>
          <span v-else>Enviar</span>
        </b-button>
        <b-button block @click="uploadTranslateFile()" v-else variant="success" :disabled="loading.uploadTranslate || !form.fileCost">
          <b-spinner v-if="loading.uploadCostTest || loading.uploadTranslate" small></b-spinner>
          <span v-else>Enviar</span>
        </b-button>
        <!-- <b-button size="sm" block @click="uploadCostFileOld()" variant="secondary" :disabled="loading.uploadCost || !form.fileCost || selectedCountry.code === 'CO'">
          <b-spinner v-if="loading.uploadCost" small></b-spinner>
          <span v-else>Enviar Antiguo</span>
        </b-button> -->
      </div>
      <div class="col-12 mt-2 d-flex justify-content-end text-right" v-if="formTypeUpload.type.id !== 3">
        <b-form-group>
          <b-form-radio-group
            id="radio-upload-cost"
            v-model="selectedValidationsBETA"
            :options="optionsValidationsBETA"
            name="update-upload-cost"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="col-12 mt-1 d-flex justify-content-end text-right" v-if="formTypeUpload.type.id !== 3">
        <b-form-group>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="allowRepeatedDeliveriesData"
            :options="allowRepeatedDeliveries"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <b-alert variant="warning" show v-if="formTypeUpload.type.id === 3 && currentCarrier" class="mt-2">
        <div class="alert-body d-flex align-items-center">
          <feather-icon class="mr-0" icon="AlertTriangleIcon" size="50"/>
          <ul class="mb-0">
            Al cargar un nuevo archivo, se <b>remplazará la información existente.</b> Descargue el archivo actual y realice sobre este los ajustes para subir la información actualizada.
          </ul>
        </div>
      </b-alert>
    </div>
    <div class="px-2" v-if="fileWithError">
      <p class="text-primary font-weight-bold pt-2">Listado de errores en el documento</p>
      <div class="error-table-container-disable">
        <table-render :key="keyTableRender" :rows="rows" :schema="schema"/>
      </div>
    </div>
  </div>
</template>
<script>
import BillingService from '../../../../billing.service'
import BaseServices from '@/store/services/index'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
  name: 'upload-cost-manage',
  components: {
    vSelect
  },
  props: ['closeModal', 'blockTabs', 'selectedCountry'],
  data() {
    return {
      excelDocUrl: null,
      fileWithError: false,
      keyTableRender: 0,
      schema: [
        {label: 'Error', key: 'title_error'},
        {label: 'Detalle', key: 'detail', useDangerHTML: true}
      ],
      rows: [],
      form: {
        fileCost: null
      },
      optionsType: [
        { id: 1, text: this.$t('Por Defecto'), name: 'regular'},
        { id: 2, text: this.$t('Tarifas por KM [Nuevo]'), name: 'distance' },
        { id: 3, text: this.$t('Places'), name: 'places' }
      ],
      fieldFormTypeUpload: [],
      formTypeUpload: {
        type: { id: 1, text: this.$t('Por Defecto'), name: 'regular'}
      },
      keyFormRenderTypeUpload: 0,
      isCountryAviable: true,
      loading: {
        uploadCost: false,
        uploadCostTest: false,
        downloadTranslate: false,
        uploadTranslate: false
      },
      fields: [],
      baseService: new BaseServices(this),
      BillingService: new BillingService(this),
      allowRepeatedDeliveriesData: [],
      allowRepeatedDeliveries: [
        { text: 'Permitir envíos repetidos', value: 'allowRepeatedDeliveries' }
      ],
      optionsValidationsBETA: [
        { text: 'Solo validar excel', value: 'validateOnly' },
        { text: 'Generar reporte', value: 'noUpdateData' },
        { text: 'Generar reporte y guardar datos', value: 'updateData', disabled: false }
      ],
      selectedValidationsBETA: 'noUpdateData',
      country: {},
      countryDownloadLinks: Object.freeze({
        CL: '/formato_importar_costos_chile.xlsx',
        CO: '/formato_importar_costos_colombia.xlsx',
        MX: '/formato_importar_costos_mexico.xlsx',
        PE: '/formato_importar_costos_peru.xlsx'
      }),
      optionsCarriers: [],
      currentCarrier: null
    }
  },
  watch: {
    selectedCountry () {
      this.setDownloadLink(this.selectedCountry.code)
      this.country = this.selectedCountry
      this.isCountryAviable = ['CL'].includes(this.selectedCountry.code)

      if (this.selectedCountry.code !== 'CL') {
        this.formTypeUpload.type = this.optionsType[0]
      }
    },
    carriersBilling() {
      this.optionsCarriers = this.carriersBilling.map(carrier => ({ text: carrier.name, value: carrier.id }))
    }
  },
  computed: {
    ...mapGetters({
      carriersBilling: 'getCarriersBilling'
    })
  },
  mounted() {
    if (this.selectedCountry) this.setDownloadLink(this.selectedCountry.code)
    this.isCountryAviable = ['CL'].includes(this.selectedCountry.code)
    this.country = this.selectedCountry
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldFormTypeUpload = [
        {fieldType: 'FieldRadio', name: 'type', type: 'type', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12', label: 'Tipo fichero de carga'}
      ]
      // fetch endpoint geCarriersBilling for set select options
      this.$store.dispatch('fetchService', { name: 'getCarriersBilling' })


    },
    changeType(_, value) {
      this.setDownloadLink(this.country.code, value.name)
      this.keyFormRenderTypeUpload++
    },
    downloadTranslate() {
      this.loading.downloadTranslate = true
      this.$store.dispatch('fetchServiceGenerateExcel', { name: 'downloadTranslate', params: { id: this.currentCarrier.value }, onSuccess: (data) => { this.loading.downloadTranslate = false }, fileName: 'translate', onError: (err) => { this.loading.downloadTranslate = false }})
    },
    uploadTranslateFile() { 
      this.loading.uploadTranslate = true
      const params = { id: this.currentCarrier.value }
      const data = {
        file: this.form.fileCost
      }

      this.baseService.callUploadFileCustomParams('uploadTranslate', data, params)
        .then(response => {
          this.$success(this.$t('msg-exito-importar-archivo'))
          this.removeFileCost()
          this.close()
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.errors[0].detail}))
        })
        .finally(() => {
          this.loading.uploadTranslate = false
        })
    },
    uploadCostFile (avoidDuplicated = {}) {
      if (!this.form.fileCost) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.blockParentTab(true)
      this.clearError()
      this.loading.uploadCostTest = true
      const data = {
        file: this.form.fileCost,
        countryCode: this.country.code,
        importType: this.formTypeUpload.type.name,
        ...avoidDuplicated
      }
      const UPDATE_DATA = 'updateData'
      const NO_UPDATE_DATA = 'noUpdateData'
      const isNoUpdateData = this.selectedValidationsBETA === NO_UPDATE_DATA

      if (isNoUpdateData) {
        data[UPDATE_DATA] = 0
      } else {
        data[this.selectedValidationsBETA] = 1
      }

      if (this.allowRepeatedDeliveriesData.length > 0) {
        data[this.allowRepeatedDeliveriesData[0]] = 1
      }

      const params = {}
      this.baseService.callUploadFileCustomParams('uploadCostFileBillingDataCompany', data, params)
        .then(response => {
          const html = `<p>${this.$t('msg-exito-importar-archivo')}</p><p>Código: ${response.meta.deliveryImportBatch.code} </p>`
          this.$success((this.selectedValidationsBETA === 'validateOnly') ? this.$t('msg-exito-validar-archivo') : html)
          this.removeFileCost()
          this.close()
        })
        .catch((err) => {
          if (err.errors && (err.errors[0]?.code === 'REPEATED_DELIVERIES' || err.errors[0]?.code === 'INCOMPLETE_DELIVERIES')) {
            this.alertDuplicetedFile(err.errors[0])
          } else if (err.errors) {
            this.$alert(this.$t('msg-problema-cargar-datos-list-errors', {code: 'Verificar listado de errores'}))
            err.errors.forEach((e) => {
              let message = ''
              Array.isArray(e.detail) ? e.detail.forEach(msg => message += `${msg}.<br>`) : message = e.detail
              this.rows.push({ title_error: e.title, detail: message })
            })
            this.fileWithError = true
          } else {
            this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
          }
        })
        .finally(() => {
          this.loading.uploadCostTest = false
          this.blockParentTab(false)
        })
    },
    alertDuplicetedFile(errors) {
      const ErrorMessage = errors.code === 'REPEATED_DELIVERIES' ? `<p>Detectamos que el archivo <b class="text-danger">contiene envíos duplicados</b>, los cuales corresponden a los siguientes:</p><p class="font-weight-bold border-bottom">Número de OT</p><div><ul>${errors.detail.map(ele => `<li>${ele.identifier} - fila: ${ele.rowNumber}</li>`).join('')}</ul></div>` : errors.detail
      Swal.fire({
        icon: 'warning',
        width: 500,
        heightAuto: false,
        html:
          `<div class="sweetAlert_cost-406">
          ${ErrorMessage}
          <hr>
          <h5 class="text-primary">¿Desea continuar con el proceso?</h5><br></div>
          `,
        customClass: {
          icon: 'sweetAlert_cost--icon',
          actions: 'sweetAlert_cost--actions'
        },
        allowOutsideClick: false,
        focusCancel: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#18a689',
        confirmButtonColor: '#dc3741',
        confirmButtonText: 'Continuar'
      })
        .then((result) => {
          //Si acepta se agregan el parametro para permitirlo dependiendo de la opcion seleccionada
          //errors.code === 'INCOMPLETE_DELIVERIES' ? '?allowIncompleteDeliveries=1' : errors.code === 'REPEATED_DELIVERIES' && this.selectedValidationsBETA === 'validateOnly' ? '?validateOnly=1&allowRepeatedDeliveries=1' : '?allowRepeatedDeliveries=1'
          const avoidDuplicated = {}

          if (errors.code === 'INCOMPLETE_DELIVERIES') {
            avoidDuplicated.allowIncompleteDeliveries = 1
          } else if (errors.code === 'REPEATED_DELIVERIES' && this.selectedValidationsBETA === 'validateOnly') {
            avoidDuplicated.validateOnly = 1
            avoidDuplicated.allowRepeatedDeliveries = 1
          } else {
            avoidDuplicated.allowRepeatedDeliveries = 1
          }
          if (result.isConfirmed) {
            this.uploadCostFile(avoidDuplicated)
          }
        })
    },
    changeFileCost(e) {
      this.form.fileCost = e.target.files[0]
    },
    setDownloadLink (code, value = 'regular') {
      if (value === 'regular') {
        this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-delivery-import${this.countryDownloadLinks[code]}`
      } else {
        this.excelDocUrl = `${this.BillingService.getCustomEnvironment('newyorkDocsCloud')}/excel-sample-delivery-import-costs-distance/formato_importar_costos_tarifas_por_km_chile.xlsx`
      }
    },
    removeFileCost() {
      this.form.fileCost = null
      this.keyFormRender++
    },
    blockParentTab (val) {
      this.$emit('blockTabs', val)
    },
    clearError() {
      if (this.fileWithError) {
        this.rows = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileCost() //ver is relamente esot es necesario
      this.loading.uploadCost = false
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .add-adition-invoices{
    width: 100%;
    span {
      width: 100% !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.8rem;
    }
  }
</style>
<style lang="scss" >
  .sweetAlert_cost-406 {
  div {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    ul {
      list-style-type: none;
      column-rule: dotted 1px #333;
      columns: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      text-align: start;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 10px;
    }
  }
}
.sweetAlert_cost-406 > h5{
  margin-bottom: 1px;
}
.sweetAlert_cost-406--btn{
  margin: 5px !important;
}
.sweetAlert_cost--icon {
  margin: 0 auto;
}
.sweetAlert_cost--actions{
  margin-top: 0;
  justify-content: space-evenly;
}
.error-table-container{
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.icon-export {
  width: 20px;
  height: 20px; 
}

</style>
